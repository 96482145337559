import React, { useEffect } from "react";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Image from "next/image";
import Link from "next/link";
import { resetState } from "@redux/helpers/localStorage";
import { serializeCookie } from "@utils/cookieUtil";
import { useRouter } from "next/router";

export default function Error() {
  const { t } = useTranslation("common");
  const { query } = useRouter();

  useEffect(() => {
    resetState();
  }, []);

  return (
    <section className="flex flex-col justify-center">
      <div className="form-container pb-4">
        <div className="flex flex-col justify-center">
          <div className="flex flex-col justify-center items-center mt-4">
            <Image src="/assets/img/error.svg" alt={"Error"} width={72} height={72}></Image>
            <p className="text-secondary mt-8 mb-2">{query.param ? t(query.param) : t("not-found")}</p>

            <Link href={"https://app.bilira.co"}>
              <p className="button1 outlined mt-8 cursor-pointer text-secondary font-bold p-8">{t("visit-bilira")}</p>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export async function getServerSideProps(context) {
  context.res.setHeader("Set-Cookie", serializeCookie("method", {}, Date.now() - 1));
  context.res.setHeader("Set-Cookie", serializeCookie("access_token", {}, Date.now() - 1));

  return {
    props: {
      ...(await serverSideTranslations(context.locale, ["common"])),
    },
  };
}
